.state-marker {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.state-marker.default {
  background: linear-gradient(60deg, #A9A9A9, #808080);
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.14), 0 3px 5px 0px rgba(128,128,128, 0.4);
}

.state-marker.danger {
  background: linear-gradient(60deg, #ef5350, #e53935);
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.14), 0 3px 5px 0px rgba(244, 67, 54, 0.4);
}

.state-marker.success {
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 4px 5px 0px rgba(0, 0, 0, 0.14), 0 3px 5px 0px rgba(76, 175, 80, 0.4);
}